<template>
  <div>
    <b-card title="Workflow Details">
      <b-row>
        <b-col
          cols="12"
          sm="4"
        >
          <dl class="row">
            <dt class="col-sm-4">
              Workflow Job Id
            </dt>
            <dd class="col-sm-8">
              {{ wfJob.jobId }}
            </dd>

            <dt class="col-sm-4">
              Group
            </dt>
            <dd class="col-sm-8">
              {{ wfJob.group }}
            </dd>

            <dt class="col-sm-4">
              Policy
            </dt>
            <dd class="col-sm-8">
              {{ wfJob.policy }}
            </dd>

            <dt class="col-sm-4">
              Workflow
            </dt>
            <dd class="col-sm-8">
              <b-link
                :to="{
                  name: 'networker-detail.workflow-detail',
                  params: { workflowName: wfJob.workflow },
                  query: { group: wfJob.group, policy: wfJob.policy },
                }"
              >
                {{ wfJob.workflow }}
              </b-link>
            </dd>
          </dl>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <dl class="row">
            <dt class="col-md-4">
              Status
            </dt>
            <dd class="col-md-8">
              <JobStatusIcon :status="wfJob.calculatedJobStatus" />
            </dd>

            <dt class="col-md-4">
              Exit code
            </dt>
            <dd class="col-md-8">
              {{ wfJob.exitCode }}
            </dd>

            <dt class="col-md-4">
              Status
            </dt>
            <dd class="col-md-8">
              {{ wfJob.status }}
            </dd>

            <dt class="col-md-4">
              Job state
            </dt>
            <dd class="col-md-8">
              {{ wfJob.jobState }}
            </dd>

            <template v-if="wfJob.parserState">
              <dt class="col-md-4">
                Parser state
              </dt>
              <dd class="col-md-8">
                {{ wfJob.parserState }}
              </dd>
            </template>

            <template v-if="wfJob.parserNote">
              <dt class="col-md-4">
                Parser note
              </dt>
              <dd class="col-md-8">
                {{ wfJob.parserNote }}
              </dd>
            </template>
          </dl>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <dl class="row">
            <dt class="col-md-4">
              Started on
            </dt>
            <dd class="col-md-8">
              {{ wfJob.start | formatDateTimeTz(asup.timezone) }}
            </dd>

            <dt class="col-md-4">
              Ended on
            </dt>
            <dd class="col-md-8">
              {{ wfJob.end | formatDateTimeTz(asup.timezone) }}
            </dd>

            <dt class="col-md-4">
              Duration
            </dt>
            <dd class="col-md-8">
              {{ wfJob.duration | formatClrTimeSpan }}
            </dd>

            <dt class="col-md-4">
              Timezone offset
            </dt>
            <dd class="col-md-8">
              {{ wfJob.dateTimeOffset | formatClrTimeSpan }}
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="wfJob.clients"
      :title="`Clients (${wfJob.clients.length})`"
    >
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Status</th>
              <th>Client</th>
              <th>Size</th>
              <th>Files</th>
              <th>Started on</th>
              <th>Ended on</th>
              <th>Duration</th>
              <th>SaveSet Jobs</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(client, index) in wfJob.clients">
              <tr :key="index">
                <td>
                  <feather-icon
                    :icon="
                      client.showDetails === true
                        ? 'ChevronUpIcon'
                        : 'ChevronDownIcon'
                    "
                    class="cursor-pointer float-left"
                    @click="toggleDetails(client)"
                  />
                  <JobStatusIcon :status="client.calculatedStatus" />
                </td>
                <td>
                  <b-link
                    :to="{
                      name: 'networker-detail.client-detail',
                      params: { clientName: client.clientName },
                    }"
                  >
                    {{ client.clientName }}
                  </b-link>
                </td>
                <td>{{ client.size | prettyBytes }}</td>
                <td>{{ client.files }}</td>
                <td>{{ client.start | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ client.end | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ client.duration | formatClrTimeSpan }}</td>
                <td>{{ client.saveSetJobs.length }}</td>
              </tr>
              <tr
                v-if="client.showDetails"
                :key="`${index}_savesets`"
              >
                <td
                  colspan="8"
                  class="pb-2 pt-2"
                >
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>SaveSet</th>
                          <th>Level</th>
                          <th>Size</th>
                          <th>Files</th>
                          <th>Started on</th>
                          <th>Ended on</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(saveSetJob,
                                  saveSetIndex) in client.saveSetJobs"
                        >
                          <tr :key="`${index}_saveset_${saveSetIndex}`">
                            <td>
                              <feather-icon
                                :icon="
                                  saveSetJob.showDetails === true
                                    ? 'ChevronUpIcon'
                                    : 'ChevronDownIcon'
                                "
                                class="cursor-pointer float-left"
                                @click="toggleDetails(saveSetJob)"
                              />
                              <JobStatusIcon
                                :status="saveSetJob.calculatedStatus"
                              />
                            </td>
                            <td>
                              <b-link
                                :to="{
                                  name: 'networker-detail.saveset-job-detail',
                                  params: {
                                    client: client.clientName,
                                    saveSetJobId: saveSetJob.jobId,
                                  },
                                }"
                              >
                                {{ saveSetJob.saveSet }}
                              </b-link>
                            </td>
                            <td>{{ saveSetJob.level }}</td>
                            <td>{{ saveSetJob.size | prettyBytes }}</td>
                            <td>{{ saveSetJob.files }}</td>
                            <td>
                              {{
                                saveSetJob.start
                                  | formatDateTimeTz(asup.timezone)
                              }}
                            </td>
                            <td>
                              {{
                                saveSetJob.end | formatDateTimeTz(asup.timezone)
                              }}
                            </td>
                            <td>
                              {{ saveSetJob.duration | formatClrTimeSpan }}
                            </td>
                          </tr>
                          <tr
                            v-if="saveSetJob.showDetails"
                            :key="`${index}_saveset_${saveSetIndex}_details`"
                          >
                            <td
                              colspan="8"
                              style="white-space: break-spaces;"
                            >
                              {{ saveSetJob.jobLog }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-card
      v-if="wfJob.cloneJobs"
      :title="`Clone Jobs (${wfJob.cloneJobs.length})`"
    >
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Status</th>
              <th>Clone</th>
              <th>Started on</th>
              <th>Ended on</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(cloneJob, index) in wfJob.cloneJobs">
              <tr :key="index">
                <td>
                  <feather-icon
                    :icon="
                      cloneJob.showDetails === true
                        ? 'ChevronUpIcon'
                        : 'ChevronDownIcon'
                    "
                    class="cursor-pointer float-left"
                    @click="toggleDetails(cloneJob)"
                  />
                  <JobStatusIcon :status="cloneJob.calculatedStatus" />
                </td>
                <td>{{ cloneJob.jobName }}</td>
                <td>{{ cloneJob.start | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ cloneJob.end | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ cloneJob.duration | formatClrTimeSpan }}</td>
              </tr>
              <tr
                v-if="cloneJob.showDetails"
                :key="`${index}_details`"
              >
                <td colspan="5">
                  <span style="white-space: pre-wrap">{{
                    cloneJob.jobLog
                  }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-card title="Workflow Job Log">
      <b-form-textarea
        :value="wfJob.jobLog"
        rows="18"
        readonly
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BCard,
  VBPopover,
  BFormTextarea,
  BLink,
} from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import NetWorkerService from '@/service/networker.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    JobStatusIcon,
    BFormTextarea,
    BLink,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wfJob: {},
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    wfJobId() {
      return this.$route.params.wfJobId
    },
    saveSetJobs() {
      if (this.wfJob.clients) {
        return this.wfJob.clients.flatMap(x => x.saveSetJobs)
      }

      return []
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      NetWorkerService.getWorkflowJobAsync(this.asup.id, this.wfJobId, {
        disableTenantFilter: true,
      }).then(result => {
        this.wfJob = result
      })
    },
    toggleDetails(data) {
      // eslint-disable-next-line no-param-reassign
      this.$set(data, 'showDetails', !data.showDetails)
    },
  },
}
</script>
